import React from "react";
import { Box, Container, Typography, Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { keyframes } from "@mui/material/styles";
import logo from "../../assets/logo-green.svg";
import mapAnimation from "../../assets/map-animation.svg";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LayersIcon from "@mui/icons-material/Layers";

const slideAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

const logos = [
  { id: 1, src: logo },
  { id: 2, src: logo },
  { id: 3, src: logo },
  { id: 4, src: logo },
  { id: 5, src: logo },
  { id: 6, src: logo },
  { id: 7, src: logo },
];

const duplicatedLogos = [...logos, ...logos];

const StatBoxTemporary = ({ icon, number, label }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      p: 3,
      textAlign: "center",
      border: "1px solid rgba(81, 243, 210, 0.12)",
      borderRadius: 2,
      height: "100%",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        borderColor: "#51f3d2",
        backgroundColor: "rgba(81, 243, 210, 0.02)",
      },
    }}
  >
    <Box
      sx={{
        color: "#51f3d2",
        mb: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
        },
      }}
    >
      {React.cloneElement(icon, { sx: { fontSize: 40 } })}
    </Box>
    <Typography
      sx={{
        fontWeight: 700,
        color: "#51f3d2",
        fontSize: { xs: "24px", md: "28px" },
        mb: 0.5,
      }}
    >
      {number}
    </Typography>
    <Typography
      sx={{
        color: "#fff",
        fontSize: "14px",
        lineHeight: 1.5,
      }}
    >
      {label}
    </Typography>
  </Box>
)

const StatBox = ({ icon, number, label }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      p: 3,
      textAlign: "center",
      border: "1px solid rgba(81, 243, 210, 0.12)",
      borderRadius: 2,
      height: "100%",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        borderColor: "#51f3d2",
        backgroundColor: "rgba(81, 243, 210, 0.02)",
      },
    }}
  >
    <Box
      sx={{
        color: "#51f3d2",
        mb: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
        },
      }}
    >
      {React.cloneElement(icon, { sx: { fontSize: 40 } })}
    </Box>
    <Typography
      sx={{
        fontWeight: 700,
        color: "#001557",
        fontSize: { xs: "24px", md: "28px" },
        mb: 0.5,
      }}
    >
      {number}
    </Typography>
    <Typography
      sx={{
        color: "#666",
        fontSize: "14px",
        lineHeight: 1.5,
      }}
    >
      {label}
    </Typography>
  </Box>
);

const MiddleSection = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ bgcolor: "#fff" }}>
      {/* European barge market section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "28px", md: "36px" },
            fontWeight: 700,
            color: "#001557",
            mb: 1,
            textAlign: "center",
          }}
        >
          {t("middle_market_title")}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            lineHeight: 1.7,
            color: "#666",
            mb: 2,
            maxWidth: "800px",
            mx: "auto",
            textAlign: "center",
          }}
        >
          {t("middle_market_desc")}
        </Typography>
        {/* Map Animation */}
        <Box
          sx={{
            width: "100%",
            height: "400px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            mb: 0,
            mt: -4,
            "&::before, &::after": {
              content: '""',
              position: "absolute",
              top: 0,
              width: "20%",
              height: "100%",
              zIndex: 2,
              pointerEvents: "none",
            },
            "&::before": {
              left: 0,
              background:
                "linear-gradient(90deg, #fff 0%, rgba(255,255,255,0) 100%)",
            },
            "&::after": {
              right: 0,
              background:
                "linear-gradient(-90deg, #fff 0%, rgba(255,255,255,0) 100%)",
            },
          }}
        >
          <Box
            component="img"
            src={mapAnimation}
            sx={{
              width: "100%",
              height: "auto",
              maxHeight: "100%",
              objectFit: "contain",
              my: -12,
            }}
          />
        </Box>
        <Grid container spacing={3} sx={{ mt: -4, mb: 4, display: "none" }}>
          <Grid item xs={12} sm={6} md={3}>
            <StatBox
              icon={<DirectionsBoatFilledIcon sx={{ fontSize: 40 }} />}
              number="1,600+"
              label={t("middle_stat_barges")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatBox
              icon={<WarehouseIcon sx={{ fontSize: 40 }} />}
              number="301"
              label={t("middle_stat_terminals")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatBox
              icon={<LocationOnIcon sx={{ fontSize: 40 }} />}
              number="5"
              label={t("middle_stat_ports")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatBox
              icon={<LayersIcon sx={{ fontSize: 40 }} />}
              number="5"
              label={t("middle_stat_themes")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ mt: -2 }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 3,
                borderLeft: "3px solid #51f3d2",
                transition: "all 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(81, 243, 210, 0.02)",
                  transform: "translateX(4px)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: 1.7,
                  color: "#666",
                }}
              >
                {t("middle_distribution_desc")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 3,
                borderLeft: "3px solid #51f3d2",
                transition: "all 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(81, 243, 210, 0.02)",
                  transform: "translateX(4px)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: 1.7,
                  color: "#666",
                }}
              >
                {t("middle_barge_desc")}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 6,
            position: "relative",
          }}
        >
          <Box
            sx={{
              maxWidth: "800px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: 3,
              p: 3,
              borderTop: "1px solid rgba(81, 243, 210, 0.12)",
              borderBottom: "1px solid rgba(81, 243, 210, 0.12)",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                borderColor: "#51f3d2",
              },
            }}
          >
            <DirectionsBoatFilledIcon
              sx={{
                fontSize: 32,
                color: "#51f3d2",
                flexShrink: 0,
              }}
            />
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: 1.7,
                color: "#001557",
                fontWeight: 500,
              }}
            >
              {t("middle_bunker_desc")}
            </Typography>
          </Box>
        </Box>
      </Container>

      {/* Trusted stakeholders section */}
      <Box
        sx={{ bgcolor: "#0A2540", py: 6, overflow: "hidden", display: "none" }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontSize: { xs: "24px", md: "28px" },
              fontWeight: 600,
              color: "#fff",
              mb: 4,
            }}
          >
            {t("middle_trusted_title")}
          </Typography>
        </Container>

        {/* Infinite scroll container */}
        <Box
          sx={{
            display: "flex",
            width: "fit-content",
            animation: `${slideAnimation} 30s linear infinite`,
          }}
        >
          {duplicatedLogos.map((logo, index) => (
            <Box
              key={`${logo.id}-${index}`}
              sx={{
                width: 200,
                height: 80,
                mx: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                filter: "brightness(0) invert(1)",
                opacity: 0.7,
                transition: "all 0.3s ease",
                "&:hover": {
                  opacity: 1,
                },
              }}
            >
              <img
                src={logo.src}
                alt={`Partner ${logo.id}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
      {/* Temporary metrics section */}
      <Box sx={{ bgcolor: "#0A2540", p: 6, overflow: "hidden" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <StatBoxTemporary
              icon={<DirectionsBoatFilledIcon sx={{ fontSize: 40 }} />}
              number="1,600+"
              label={t("middle_stat_barges")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatBoxTemporary
              icon={<WarehouseIcon sx={{ fontSize: 40 }} />}
              number="301"
              label={t("middle_stat_terminals")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatBoxTemporary
              icon={<LocationOnIcon sx={{ fontSize: 40 }} />}
              number="5"
              label={t("middle_stat_ports")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatBoxTemporary
              icon={<LayersIcon sx={{ fontSize: 40 }} />}
              number="5"
              label={t("middle_stat_themes")}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MiddleSection;
